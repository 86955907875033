@import url('https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Dosis:wght@500&family=Jost:wght@300&family=Poppins:wght@400;500;600;700&family=Roboto+Slab&family=Ubuntu:wght@400;500;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
}

body {
  background: black !important;
}

.custom-navbar {
  padding: 10px 100px !important;
}

.nav-item {
  text-align: center;
}

.header-login-lets-talk {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.search-button {
  background: rgb(243, 244, 246);
  height: 30px;
  width: 30px;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-bar {
  position: absolute;
  top: 50%;
  left: -240%;
  transform: translate(-50%, -50%);
  width: 750px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 0 10px;
  font-size: 16px;
  background: rgb(243 244 246);
  outline: none;
}

.search-bar:focus {
  border: 2px solid #00b2fe !important;
  border-radius: 10px;
}

.brand-logo {
  height: 40px;
}

.primary-button{
  background: linear-gradient(90deg, #5c2cb8, #7d3560) !important;
  border-radius: 10px !important;
  color: white !important;
  text-decoration: none;
  font-size: 12px;
  text-align: center;
  width: fit-content;
  padding: 5px 10px;
}

.login-button {
  border: none;
  padding: 7px 20px !important;
  background: rgb(31, 41, 55) !important;
  color: white !important;
  border-radius: 10px !important;
}

.login-button:hover {
  padding: 7px 20px !important;
  background: rgb(31, 41, 55) !important;
  color: white !important;
}

.navbar-toggler {
  color: black !important;
  background-color: black !important;
}

.trending-container {
  display: flex;
  overflow-x: auto;
  padding: 20px;
  gap: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.trending-container::-webkit-scrollbar {
  display: none;
}


.trending-item {
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
}

.trending-item.large {
  background: linear-gradient(180deg, rgb(136 169 194), #00b2fe);
  color: black;
  width: 500px;
  height: 350px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 1rem;
  /* text-align: center; */
  border-radius: 15px;
  position: relative;
}

.trending-item.medium {
  background: linear-gradient(180deg, rgb(136 169 194), #00b2fe);
  color: black;
  width: 300px;
  height: 350px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 1rem;
  /* text-align: center; */
  border-radius: 15px;
  position: relative;
}

.trending-item.medium h1 {
  margin: 0;
  font-size: 2rem;
}

.trending-item.medium button,
.trending-item.large button {
  padding: 7px 20px !important;
  background: rgb(31 41 55) !important;
  color: white !important;
  border-radius: 10px !important;
}

.trending-item.medium .zorb-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  height: 100%;
  opacity: 0.1;
}

.trending-item.large .content p {
  font-size: 1.2rem;
  line-height: 1.5;
  margin: 0;
}

.trending-item.medium .content {
  z-index: 1;
}

.trending-item.medium .button-container {
  z-index: 1;
}


.trending-item.small {
  width: 250px;
  height: 350px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding: 10px; */
  border: 1px solid #ccc;
}

.trending-item img {
  width: 100%;
  border-radius: 10px;
}

.trending-item.small p {
  color: white;
  position: absolute;
  bottom: 0;
  left: 10px;
  font-weight: 700;
}

.trending-item-small .user-name {
  padding-left: 10px;
  font-weight: 600;
  color: white;
}

.trending-item span {
  font-weight: bold;
}

.trending-item button {
  background: black;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.trending-item button:hover {
  background: #333;
}

.card {
  width: 345px !important;
  margin: 10px !important;
  border-radius: 10px !important;
  overflow: hidden !important;
  background: #000 !important;
  transition: transform 0.2s !important;
  border: none !important;
  color: white !important;
}

.card:hover {
  cursor: pointer;
}

.card-image {
  position: relative;
}

.card-image img {
  width: 100%;
  border-radius: 10px !important;
  display: block;
}

.card-duration {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
}

.card-content {
  padding: 15px 0px;
}

.user-icon img {
  height: 35px;
  width: 35px;
}

.card-title {
  font-size: 17px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 10px;
}

.card-user {
  display: flex;
  align-items: center;
  gap: 5px;
}

.verified-icon {
  color: #06aaf1;
}

.card-details {
  display: flex;
  font-size: 14px;
  gap: 0.2rem;
  color: gray;
}

.card-likes {
  display: flex;
  align-items: center;
  gap: 5px;
}

.home-explore-container {
  padding: 20px;
}

.home-explore-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.home-explore-header h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.home-explore-tabs {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  padding-bottom: 10px;
}

.home-explore-tabs button {
  background: none;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-bottom: 2px solid transparent;
  color: white;
}

.home-explore-tabs button.active,
.home-explore-tabs button:hover,
.home-explore-tabs button:focus {
  border-bottom: 2px solid #0072ff;
  background: linear-gradient(to bottom, #000000 0%, #003366 100%);
  outline: none;
}

.home-explore-cards {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  gap: 6px;
}

.explore-page {
  color: white;
  font-family: 'Arial', sans-serif;
  background-color: #000;
  min-height: 100vh;
}

.explore-page .explore-header {
  text-align: center;
  padding: 1.5rem 0;
  background: linear-gradient(90deg, #5c2cb8, #7d3560);
  margin-bottom: 30px;
}

.explore-page .explore-header h1 {
  font-size: 2rem;
  /* margin-bottom: 20px; */
  font-weight: 700;
}

.explore-page .explore-header input {
  padding: 20px 30px;
  width: 40%;
  border: none;
  border-radius: 30px;
  font-size: 1rem;
  outline: none;
  background: rgb(11 2 32);
  color: white;
  font-size: 1.1rem;
}

.explore-page .featured-container {
  flex-wrap: wrap;
  padding: 5rem 10rem;
  gap: 2rem;
  background: transparent;

  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .explore-page .featured-container {
    flex-direction: column;
    align-items: center;
  }
}

.explore-card {
  background: linear-gradient(90deg, #5c2cb8, #7d3560);
  border-radius: 12px;
  overflow: hidden;
  color: white;
  transition: transform 0.3s ease;
  cursor: pointer;
  position: relative;
  padding: 1.5rem 1.5rem;
  flex: 0 1 calc(50% - 1rem);
  display: flex;
}

.explore-card .bg-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  opacity: 0.1;
}

.explore-card-image {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
}

.explore-card-image img {
  height: 7rem;
  width: 7rem;
  border-radius: 12px;
}

.explore-card-content {
  padding-left: 30px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 5;
  gap: 8px;
}

.explore-card-title {
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: bold;
  z-index: 1;
}

.explore-card-meta {
  font-size: 0.9rem;
  color: #fff;
  margin-bottom: 15px;
}

.explore-card-meta span {
  margin-right: 10px;
}

.explore-card-description {
  font-size: 14px;
  color: #fff;
}



.bytes-page-container {
  background-color: #000;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.bytes-page-inside-container {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}

.bytes-page-reel {
  min-width: 400px;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.bytes-page-card {
  width: 100%;
  /* max-width: 400px; */
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: end;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  position: absolute;
  transition: transform 0.5s ease;
}

.bytes-page-card.hidden {
  opacity: 0;
  visibility: hidden;
}

.bytes-page-card.active {
  opacity: 1;
  visibility: visible;
}

.bytes-page-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.bytes-page-card-content {
  padding: 20px;
  z-index: 2;
  position: relative;
  color: white;
}

.bytes-page-card-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.bytes-page-card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.bytes-page-card-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.bytes-page-card-username {
  margin-left: 10px;
  font-weight: bold;
}

.bytes-page-card-followers {
  margin-left: 10px;
  font-size: 0.9rem;
  color: #fff;
}

.bytes-page-card-follow-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #0095f6;
  color: white;
  cursor: pointer;
}

.bytes-page-card-actions {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  padding: 20px;
  z-index: 2;
  gap: 1.5rem;
}

.bytes-page-action-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bytes-page-action-button span {
  font-size: 0.9rem;
}

/* Animation */
.bytes-page-card.slide-up-in {
  animation: slideUpIn 0.5s ease-out forwards;
}

.bytes-page-card.slide-up-out {
  animation: slideUpOut 0.5s ease-in forwards;
}

.bytes-page-card.slide-down-in {
  animation: slideDownIn 0.5s ease-out forwards;
}

.bytes-page-card.slide-down-out {
  animation: slideDownOut 0.5s ease-in forwards;
}

@keyframes slideUpIn {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideUpOut {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

@keyframes slideDownIn {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideDownOut {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}

.bytes-page-navigation-buttons {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
}

.bytes-page-nav-button {
  background-color: #f0f0f0;
  border: none;
  border-radius: 50%;
  padding: 10px;
  margin: 5px 0;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
}

.bytes-page-nav-button:hover {
  background-color: #e0e0e0;
}

.uploadeMusicForm {
  width: 60%;
}

.video-frame iframe {
  width: 100%;
  height: 515px;
}

.uploadeMusicForm {
  border-radius: 4px;
}

.theme-btn-one {
  border: none;
  padding: 7px 20px !important;
  background: rgb(31, 41, 55) !important;
  color: white !important;
  border-radius: 10px !important;
}

.theme-background-color-one {
  background-color: #ffffff17 !important;
}

.uploadeMusicForm label {
  color: #fff;
}

.theme-box-shadow {
  box-shadow: 1px 1px 3px 1px #ffffff42;
}

.moreOption {
  top: 100%;
  right: 0;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1;
  padding: 5px;
}

.moreOption ul {
  min-width: 140px;
}

.moreOption ul li {
  cursor: pointer;
  color: #000;
  padding: 5px 10px;
}

.moreOption ul li:hover {
  color: #fff;
  background-color: #000;
}

.group-logic {
  background-color: #ccc;
  padding: 12px 25px;
  border-radius: 24px;
}

.group-logic div {
  padding: 0px 5px;
  color: black;
}

.login-form {
  padding: 25px 30px;
  width: 40% !important;
  border-radius: 5px;
  color: #fff;
}

.inner-login {
  padding: 15px;
}


.dropdown-item {
  color: white !important;
}

.dropdown-item:hover {
  color: black !important;
}


.mobile-box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.left-text img, .right-text img {
  filter: invert(1) brightness(2);
}

.mobile-image {
  max-width: 600px;
  padding: 2rem;
  position: relative;
}

.mobile-image .mobile-main-image {
  width: 250px;
}

.left-text {
  position: absolute;
  right: 90%;
  width: 250px;
  bottom: 0px;
  z-index: -1;
}

.right-text {
  position: absolute;
  left: 85%;
  width: 300px;
  z-index: -1;
  top: 50px;
}

.right-text2 {
  position: absolute;
  display: none;
}



.shuffle-video {
  position: absolute;
  background: #1d0123;
  height: 85%;
  border-radius: 30px;
  display: flex;
  align-items: center;
}

.shuffle-video video {
  width: 222px;
}

.get-your-app-main {
  position: absolute;
  left: 102%;
  bottom: 80px;
}

.get-your-app-main p {
  font-size: 16px;
  margin-bottom: 8px;

}

.get-your-app {
  width: 230px;
  display: flex;
  gap: 1rem;
}


.terms-of-service {
  margin: 0rem 5rem;
  min-height: 60vh;
}

.terms-of-service h1 {
  text-align: center;
  cursor: auto;
  font-weight: 700;
  background: linear-gradient(89.92deg, #e419f3 0%, #e419f3 70%, #e419f3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.terms-of-service h3 {
  font-weight: 700;
  background: linear-gradient(89.92deg, #e419f3 0%, #e419f3 70%, #e419f3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.checkout__step-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  padding: 10px 0px;
  cursor: pointer;
  margin-bottom: 10px;
}

.checkout__step-header h5 {
  font-weight: 700;
}


.btn-primary {
  background: linear-gradient(90deg, #5c2cb8, #7d3560);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  font-weight: 700;
}

.btn-primary2 {
  background: linear-gradient(90deg, #5c2cb8, #7d3560);
  border: 2px solid black;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  font-weight: 700;
}

.dance-hero h1 {
  background: linear-gradient(90deg, rgba(232, 95, 255, 1) 0%, rgba(232, 95, 255, 1) 25%, rgba(141, 0, 255, 1) 50%, rgba(232, 95, 255, 1) 71%, rgba(232, 95, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 4rem;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  /* font-family: 'Ubuntu', sans-serif; */
}

.dance-hero p {
  color: #fff;
  font-size: 1.5rem;
  font-family: 'Ubuntu', sans-serif;
}

.dance-hero p span {
  background: linear-gradient(90deg, rgba(232, 95, 255, 1) 0%, rgba(232, 95, 255, 1) 25%, rgba(141, 0, 255, 1) 50%, rgba(232, 95, 255, 1) 71%, rgba(232, 95, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2rem;
  font-weight: 700;
}

.dance-hero-image {
  width: 26rem !important;
  margin-top: 8rem;
}

.hero-dance-text {
  position: relative;
}

.hero-dance-text div {
  background: linear-gradient(90deg, rgba(232, 95, 255, 1) 0%, rgba(232, 95, 255, 1) 25%, rgba(141, 0, 255, 1) 50%, rgba(232, 95, 255, 1) 71%, rgba(232, 95, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
  font-size: 10rem;
}

.d-text {
  top: 8%;
  left: 49.5%;
  z-index: -1;
}

.a-text {
  top: 22%;
  left: 56%;
  z-index: -1;
}

.n-text {
  top: 39%;
  left: 60%;
  z-index: 1;
}

.c-text {
  top: 55%;
  left: 56%;
  z-index: 1;
}

.e-text {
  top: 69%;
  left: 49.5%;
  z-index: 1;
}

.prizes {
  display: flex;
}

.prizes-2 {
  display: flex;
  flex-direction: row-reverse;
}

.prizes-3 {
  display: flex;
}

.prizes-4 {
  display: flex;
  flex-direction: row-reverse;
}

.prizes-5 {
  display: flex;
}

.prizes-text {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.prizes-2 .prizes-text {
  align-items: center;
}

.prizes-4 .prizes-text {
  align-items: start;
}

.prizes-3 .prizes-text {
  align-items: center;
}

.prizes-5 .prizes-text {
  align-items: start;
}

.prizes-text h1 {
  background: linear-gradient(90deg, rgba(232, 95, 255, 1) 0%, rgba(232, 95, 255, 1) 25%, rgba(141, 0, 255, 1) 50%, rgba(232, 95, 255, 1) 71%, rgba(232, 95, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 3rem;
}

.prizes-text p, .prizes-text li {
  font-family: 'Ubuntu', sans-serif;
}

.prizes-text p span {
  background: linear-gradient(90deg, rgba(232, 95, 255, 1) 0%, rgba(232, 95, 255, 1) 25%, rgba(141, 0, 255, 1) 50%, rgba(232, 95, 255, 1) 71%, rgba(232, 95, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.2rem;
}

.box-div {
  /* border: 2px solid red; */
  /* display: flex;
  flex-direction: column; */
  /* align-items: center; */
}

.box {
  background: linear-gradient(90deg, #5c2cb8, #7d3560);
  width: 29rem;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 0;
}

.box b {
  font-weight: 700;
  font-size: 1.2rem;
}

.prizes-3 .box {
  width: 30rem;
}



.prizes-img {
  flex: 1;
  display: flex;
  justify-content: start;
}

.prizes-2 .prizes-img {
  justify-content: start;
}

.prizes-3 .prizes-img {
  justify-content: end;
}

.prizes-4 .prizes-img {
  justify-content: center;
}

.prizes-5 .prizes-img {
  justify-content: center;
  transform: scaleX(-1)
}

.prizes-2 .prizes-img img {
  width: 90%;
}

.prizes-3 .prizes-img img {
  width: 90%;
}

.prizes-4 .prizes-img img {
  width: 80%;
}

.prizes-img img {
  width: 90%;
}

.section-title {
  background: linear-gradient(90deg, rgba(232, 95, 255, 1) 0%, rgba(232, 95, 255, 1) 25%, rgba(141, 0, 255, 1) 50%, rgba(232, 95, 255, 1) 71%, rgba(232, 95, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-size: 4rem;
  font-weight: 700;
}

.section-div {
  display: flex;
}

.section-text {
  flex: 1;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section-image {
  flex: 1;
  display: flex;
  align-items: center;
}

.section-header {
  font-size: 3rem;
  font-weight: 700;
  background: linear-gradient(90deg, rgba(232, 95, 255, 1) 0%, rgba(232, 95, 255, 1) 25%, rgba(141, 0, 255, 1) 50%, rgba(232, 95, 255, 1) 71%, rgba(232, 95, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.microdramas-banner2 {
  /* background: url('./breadcrumb.jpg') no-repeat center center; */
  /* min-height: 70vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.microdramas-banner2 h1{
  background: linear-gradient(90deg, rgba(232, 95, 255, 1) 0%, rgba(232, 95, 255, 1) 25%, rgba(141, 0, 255, 1) 50%, rgba(232, 95, 255, 1) 71%, rgba(232, 95, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dramas-hero {
  height: 100vh;
  min-height: fit-content;
  background: url('./banner.jpg') no-repeat center center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5rem;
}

.dramas-hero-text {
  margin-top: 2rem;
  width: 50%;
  margin-bottom: 2rem;
}

.dramas-hero-text h1 {
  font-size: 4rem;
  font-weight: 700;
  background: linear-gradient(90deg, rgba(232, 95, 255, 1) 0%, rgba(232, 95, 255, 1) 25%, rgba(141, 0, 255, 1) 50%, rgba(232, 95, 255, 1) 71%, rgba(232, 95, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dramas-hero-text h3 {
  font-size: 2rem;
}

.dramas-hero-text h5 {
  margin-bottom: 1rem;
}

.dramas-hero-text p span {
  /* color: #e419f3; */
  font-weight: bold;
}


.footer {
  padding: 20px 0;
  margin-top: auto;
}

.footer-divider {
  background: rgba(255, 255, 255, 0.2);
  height: 2px;
  border: none;
  margin: 0 0 20px 0;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 0 auto;
  padding: 0 15px;
}

.footer-links a {
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
  transition: color 0.2s ease;
  font-size: 14px;
  white-space: nowrap;
}

.footer-links a:hover {
  color: #ffffff;
}

.footer-copyright {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
}

.contact-form-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin: 20px 5rem;
  padding: 20px;
  font-family: Arial, sans-serif;
  border-radius: 10px;
}

.contact-form {
  flex: 1;
  background: #ffffff;
  padding: 30px;
  border: 1px solid #ddd;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contact-form h2 {
  margin-bottom: 20px;
  /* font-size: 24px; */
  font-weight: 700;
  color: #333;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.contact-form button {
  padding: 10px 20px;
  background: linear-gradient(90deg, #5c2cb8, #7d3560);
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  width: fit-content;
  transition: all 0.3s ease;
}

.contact-form button:hover {
  scale: 1.05;
  transition: all 0.3s ease;
}

.contact-info {
  flex: 1;
  background: linear-gradient(90deg, #5c2cb8, #7d3560);
  color: #fff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.contact-info h2 {
  margin-bottom: 20px;
  font-weight: 700;

  /* font-size: 24px; */
}

.contact-info .contact-info-parts {
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 14px;
  line-height: 1.5;
}

.contact-info .icon {
  font-size: 20px;
  margin-right: 10px;
  color: #ffffff;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
}
.contact-info strong {
  padding-right: 5px;
}




























/* Responsive adjustments */
@media (max-width: 768px) {
  .footer-links {
    gap: 15px;
    flex-direction: column;
    text-align: center;
  }

  .footer-links a {
    font-size: 13px;
  }
}

@media screen and (max-width: 1200px) {
  .dramas-hero-text {
    width: 80%;
  }

  .explore-page .featured-container {
    padding-left: 0rem;
    padding-right: 0rem;
  }
}


@media screen and (max-width: 992px) {

  .a-text {
    z-index: 1;
  }

  .prizes-img {
    justify-content: end;
  }

  .prizes-2 .prizes-img img {
    width: auto;
  }

  .prizes-2 .prizes-img {
    justify-content: start;
  }

  .prizes-2 {
    flex-direction: column-reverse;
    gap: 2rem;
  }

  .prizes-2 .prizes-text {
    align-items: center;
  }

  .prizes-3 {
    flex-direction: column-reverse;
    gap: 2rem;
  }

  .section-1 {
    flex-direction: column;
  }

  .section-div .section-text, .section-div .section-image {
    padding: 0rem !important;
  }

  .section .section-2{
    display: flex;
    flex-direction: column !important;
    gap: 2rem;
  }

  .section .section-3{
    display: flex;
    flex-direction: column !important;
    gap: 2rem;
  }

  .section .section-4{
    display: flex;
    flex-direction: column !important;
    gap: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .d-text {
    z-index: 1;
  }

  .left-text {
    display: none;
  }

  .right-text {
    display: none;
  }

  .get-your-app-main {
    left: 15%;
    bottom: -15%;
  }

  .mobile-box {
    margin-top: 15rem;
    margin-bottom: 5rem;
  }

  .right-text2 {
    display: block;
    top: -40%;
    left: -15%;
    width: 450px;
    z-index: -1;
  }

  .right-text2 img {
    filter: invert(1) brightness(2);
    width: 100% !important;
  }

  .prizes {
    flex-direction: column;
  }

  .prizes-4 {
    flex-direction: column-reverse;
    gap: 2rem;
  }

  .prizes-5 {
    flex-direction: column-reverse;
    gap: 2rem;
  }

  .section-title {
    font-size: 3rem;
  }

  .dramas-hero-text h1 {
    font-size: 3rem;
  }

  .section.microdramas-banner2 h1{
    font-size: 3rem !important;
  }
  .section.microdramas-banner2 p{
    font-size: 1rem !important;
  }
}

@media screen and (max-width: 576px) {
  .custom-navbar {
    padding: 10px 50px !important;
  }

  .prizes-3 .box, .box {
    width: 100%;
  }

  .prizes-3 .prizes-text {
    padding-left: 0rem !important;
  }

  .section-title {
    font-size: 2.5rem;
  }

  .dramas-hero {
    padding-left: 2rem;
  }
  .dramas-hero h3{
    font-size: 1.5rem;
  }
  .dramas-hero h1{
    font-size: 2.5rem;
  }

  .explore-page .featured-container {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media screen and (max-width: 450px) {
  .right-text2 {
    width: 320px;
    top: -30%;
    left: 0%;
  }

  .section-title, .prizes-text h1 {
    font-size: 2rem;
  }

  .dance-hero h1 {
    font-size: 3rem;
  }

  .prizes-5 .prizes-text {
    padding-left: 0rem !important;
  }
}

@media screen and (max-width: 400px) {
  .dramas-hero h1{
    font-size: 2rem;
  }

  .dramas-hero h3{
    font-size: 1rem;
  }

  .dramas-hero h5{
    font-size: 1rem;
  }

  .dramas-hero p{
    font-size: 1rem;
  }

  .explore-page .featured-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media screen and (max-width: 350px) {
  .custom-navbar {
    padding: 10px !important;
  }

  .section-title, .prizes-text h1 {
    font-size: 1.5rem;
  }
}