@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.swap-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 5rem;
  /* background: linear-gradient(90deg, #3C2A5F 0.62%, #945276 100%); */
}

.swap-container .swap-title {
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.swap-container .swap-title .settings-icon {
  font-size: 2.5rem;
}

.swap-container .swap-box {
  background: linear-gradient(90deg, #3B1A61 0%, #822457 100%);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  width: 500px;
  text-align: center;
}

.swap-container .swap-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  background: rgba(84, 23, 82, 1);
  border: 1px solid rgba(232, 239, 251, 0.2);
  border-radius: 10px;
  padding: 0.5rem;
}

.swap-container .swap-input .custom-select {
  position: relative;
  cursor: pointer;
  background: rgba(156, 73, 133, 1);
  padding: 0.5rem;
  border-radius: 8px;
  width: 25% !important;
}

.swap-container .swap-input .custom-select .selected-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.swap-container .swap-input .custom-select img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.swap-container .swap-input .custom-select .arrow {
  margin-left: auto;
  padding-left: 5px;
}

.swap-container .swap-input .custom-select .dropdown-options {
  display: none;
  position: absolute;
  background-color: rgba(84, 23, 82, 1);
  border: 1px solid rgba(232, 239, 251, 0.2);
  border-radius: 8px;
  margin-top: 5px;
  z-index: 1;
  width: 100%;
}

.swap-container .swap-input .custom-select .dropdown-options .custom-option {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.swap-container .swap-input .custom-select .dropdown-options .custom-option:hover {
  background: rgba(156, 73, 133, 1);
}

.swap-container .swap-input .custom-select.open .dropdown-options {
  display: block;
}

.swap-container .swap-input input {
  width: 45%;
  background: transparent;
  border: none;
  width: auto;
  outline: none;
  padding: 0rem 0.5rem;
  color: white;
}

.swap-container .swap-input span {
  width: 30%;
}

.swap-container .swap-details2 {
  margin-top: 10px;
  background: rgba(84, 23, 82, 1);
  border-radius: 10px;
  padding: 1rem;
}

.swap-container .swap-details2 p {
  display: flex;
  justify-content: space-between;
}

.swap-container .swap-details2 span {
  font-weight: 600;
}

.swap-container .swap-input input::placeholder {
  color: rgb(180, 180, 180);
}

.swap-container .usd-value {
  font-size: 0.9rem;
  color: #aaa;
}

.swap-container .swap-details {
  text-align: left;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.swap-container .swap-details p {
  margin: 0.2rem 0;
}

.swap-container .route-view {
  color: #ff69b4;
  cursor: pointer;
}

.swap-container .connect-wallet-btn {
  padding: 0.7rem 1.5rem;
  background: rgba(156, 73, 133, 1);
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
}

.swap-container .connect-wallet-btn:hover {
  background: #ff4da6;
}


.swap-container .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.swap-container .modal-content {
  background: linear-gradient(90deg, #3B1A61 0%, #822457 100%);
  border-radius: 10px !important;
  width: 500px !important;
  padding: 1.5rem;
  color: white;
  text-align: center;
}
.swap-container .choose-provider-modal-content {
  background: linear-gradient(90deg, #3B1A61 0%, #822457 100%);
  border-radius: 10px !important;
  width: 600px !important;
  padding: 1.5rem;
  color: white;
  text-align: center;
}

.swap-container .qr-modal-content {
  background: linear-gradient(90deg, #3B1A61 0%, #822457 100%);
  border-radius: 10px !important;
  width: 350px !important;
  padding: 1.5rem;
  color: white;
  text-align: center;
}

.swap-container .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.swap-container .modal-header h3 {
  color: white;
  margin: 0;
}

.swap-container .modal-header p {
  color: white;
  margin: 0;
  font-size: 1rem;
}

.swap-container .modal-header button {
  background: none;
  border: none;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
}

.swap-container .modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.swap-container .modal-body input {
  width: 100%;
  padding: 0.5rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  background: rgba(84, 23, 82, 1);
  border: 1px solid rgba(232, 239, 251, 0.2)
}

.swap-container .modal-body input::placeholder {
  color: white;
}

.swap-container .token-list {
  width: 100%;
  background: rgba(84, 23, 82, 1);
  padding: 1rem;
  border-radius: 10px;
}

.swap-container .token-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.swap-container .token-details {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  color: white;
}

.swap-container .token-details h6 {
  font-weight: 700;
}

.swap-container .token-name {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.swap-container .token-item {
  background: rgba(84, 23, 82, 1);
}

.swap-container .token-details img{
  width: 33px;
  height: 33px;
}

.swap-container .right-arrow {
  color: white;
  font-weight: 800;
  font-size: 1.2rem;
}

.swap-container .wallet-option {
  display: flex;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  background: rgba(84, 23, 82, 1);
  margin-bottom: 1rem;
  border-radius: 8px;
  width: 100%;
  justify-content: space-between;
  color: white;
}

.swap-container .wallet-details {
  display: flex;
  align-items: center;
}

.swap-container .wallet-option img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.swap-container .wallet-option p {
  margin: 0;
  font-size: 1.2rem;
}

.swap-container .qr-content {
  color: white;
  text-align: left;
  padding: 1rem 1rem;
}

.swap-container .settings-section{
  color: white;
  text-align: left;
}
.swap-container .settings-section p{
  font-weight: 600;
  font-size: 0.9rem;
}

.swap-container .option-buttons{
  display: flex;
  gap: 10px !important;
}
.swap-container .option-buttons button{
  background: rgba(84, 23, 82, 1);
  border: none;
  padding: 8px 1rem;
  border-radius: 10px;
  color: white;
}
.swap-container .option-buttons button:active, .swap-container .option-buttons button:hover{
  background: linear-gradient(90deg, #F472E7 0%, #D18282 100%);
}

.swap-container .form-switch{
  display: flex !important;
  justify-content: space-between !important;
  padding-left: 0 !important;
}

.swap-container .form-check-input[type=checkbox]:checked {
  background-color: #F472E7;
  border-color: #F472E7;
}

.swap-container .save-settings-btn{
  background: rgba(156, 73, 133, 1);
  color: white;
  padding: 17px 32px;
  border-radius: 10px;
  border: none;
  width: 100%;
}

.swap-container .liquidity-content {
  background: linear-gradient(90deg, #3B1A61 0%, #822457 100%);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  width: 500px;
  text-align: center;
}
.swap-container .add-liquidity-content {
  background: linear-gradient(90deg, #3B1A61 0%, #822457 100%);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  width: 550px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.swap-container .liquidity-header{
  text-align: left;
  width: 100%;
}

.swap-container .liquidity-bottom-content{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.swap-container .liquidity-bottom-content p{
  width: 60%;
}

.swap-container .add-liquidity-content .swap-box {
  width: 500px;
}
.swap-container .swap-balance{
  text-align: end;
}

.swap-container .add-liquidity-image{
  transform: scaleX(-1);
}


.swap-container .send-details {
  text-align: start;
}

.swap-container .send-details label {
  display: block;
  margin-bottom: 5px;
}

.send-details input,
.swap-container .send-details select {
  background: rgb(84 23 82);
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  margin-bottom: 10px;
  border: 1px solid #E8EFFB33;
  outline: none;
}

.swap-container .send-details select option span{
}

.swap-container .eth-usd {
  text-align: start;
  color: #b3b3b3;
  font-size: 0.9rem;
}

.swap-container .liquidity-pools-table {
  font-family: 'Arial', sans-serif;
  color: #fff;
  background: linear-gradient(90deg, #3C2A5F 0.62%, #945276 100%);
  padding: 4rem;
  /* border-radius: 10px; */
  width: 100%;
  margin: 0 auto;
}

.swap-container .liquidity-pools-table h2 {
  margin-bottom: 10px;
}

.swap-container .liquidity-pools-table p {
  margin-bottom: 20px;
}

.swap-container .table-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background: #541752;
  padding: 0.6rem 2rem;
  border-radius: 10px;
}

.swap-container .table-controls .live, .swap-container  .table-controls .finished {
  background: transparent;
  border: none;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}


.swap-container .table-controls .live-finshed-buttons{
  display: flex;
  gap: 20px;
}

.swap-container .table-controls .live-finshed-buttons button:active{
  background: linear-gradient(90deg, #F472E7 0%, #D18282 100%);
}

.swap-container .table-controls .live-finshed-buttons button:hover{
  background: linear-gradient(90deg, #F472E7 0%, #D18282 100%);
}

.swap-container .table-controls input {
  padding: 8px 10px;
  border-radius: 10px;
  width: 460px;
  background: #7F2E61;
  border: 1px solid #E8EFFB33;
  color: white;
  outline: none;
}
.swap-container .table-controls input::placeholder {
  color: white;
}

.swap-container .table-wrapper {
  padding: 1rem 2rem; 
  background: linear-gradient(90deg, #3B1A61 0%, #822457 100.02%);
  border-collapse: collapse;
  border-radius: 20px;
}

.swap-container table {
  width: 100%;
}

.swap-container th, td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #444;
}

.swap-container th {
  background: transparent;
}

.swap-container td {
  background: transparent;
}

.swap-container tbody tr:nth-child(odd) td {
  background: #FFFFFF0D;
}

.swap-container tbody tr:nth-child(odd) td:first-child {
  border-top-left-radius: 20px;
}

.swap-container tbody tr:nth-child(odd) td:last-child {
  border-top-right-radius: 20px;
}

.swap-container tbody tr:nth-child(odd) td:first-child {
  border-bottom-left-radius: 20px;
}

.swap-container tbody tr:nth-child(odd) td:last-child {
  border-bottom-right-radius: 20px;
}


.swap-container .buttons{
  display: flex;
  gap: 10px;
}
.swap-container .buttons button{
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: white;
  font-weight: 800;
}


.swap-container .plus-btn{
  background: #6DBAE6;
}

.swap-container .minus-btn{
  background: #4CAF50;
}